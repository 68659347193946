import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {Form, SelectField, Option, InputCurrencyField, useStyles} from '@ace-de/ui-components';
import ReturnJourneyAndShortTripSubForm from './ReturnJourneyAndShortTripSubForm';
import AccommodationSubForm from './AccommodationSubForm';
import RentalCarSubForm from './RentalCarSubForm';
import {journeyContinuationServices} from '../constants/journeyContinuationServices';

const JourneyContinuationAndAccommodationForm = props => {
    const {cx} = useStyles();
    const {service} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateForm = createTranslateShorthand('journey_continuation_and_accommodation');
    const translateInvoiceForm = createTranslateShorthand('invoice_submission_global');
    const [errors, setErrors] = useState({
        type: false,
        requestedInvoicingAmount: false,
    });

    const handleErrors = (field, value) => {
        if (!field) return;
        setErrors(prevState => ({
            ...prevState,
            [field]: !value,
        }));
    };

    return (
        <Form name="journeyContinuationAndAccommodation">
            {formValues => (
                <Fragment>
                    <h3
                        className={cx([
                            'global!ace-u-typography--variant-h3',
                            'global!ace-u-margin--small-bottom-24',
                        ])}
                    >{translateForm('title.select_additional_service')}
                    </h3>
                    <SelectField
                        name="type"
                        label={translateForm('select_label.service_type')}
                        className={cx([
                            'global!ace-u-full-width',
                            'global!ace-u-margin--small-bottom-32',
                        ])}
                        value={service?.type || ''}
                        placeholder={translateForm('select_placeholder.service_type')}
                        onBlur={value => handleErrors('type', value)}
                        errors={errors.type ? [translate('global.error.empty_mandatory_field')] : []}
                    >
                        {Object.keys(journeyContinuationServices).map(type => {
                            return <Option name={`${type}-option`} key={type} value={type}>{translate(`global.journey_continuation_service.${type.toLowerCase()}`)}</Option>;
                        })}
                    </SelectField>
                    {!!formValues?.type && (
                        <Fragment>
                            <h3
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--small-bottom-16',
                                ])}
                            >
                                {translateInvoiceForm('title.total_amount')}
                            </h3>
                            <InputCurrencyField
                                placeholder={translateInvoiceForm('input_placeholder.requested_invoicing_amount')}
                                name="requestedInvoicingAmount"
                                label={translateInvoiceForm('input_label.requested_invoicing_amount')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                                value={service?.requestedInvoicingAmount || ''}
                                type="number"
                                onBlur={value => handleErrors('requestedInvoicingAmount', value)}
                                errors={errors.requestedInvoicingAmount ? [translate('global.error.invalid_entry')] : []}
                            />
                        </Fragment>
                    )}
                    {formValues?.type === journeyContinuationServices.ACCOMMODATION && (
                        <AccommodationSubForm
                            service={service}
                        />
                    )}
                    {(formValues?.type === journeyContinuationServices.SHORT_TRIP
                        || formValues?.type === journeyContinuationServices.FURTHER_JOURNEY) && (
                        <ReturnJourneyAndShortTripSubForm
                            service={service}
                        />
                    )}
                    {formValues?.type === journeyContinuationServices.RENTAL_CAR && (
                        <RentalCarSubForm
                            service={service}
                        />
                    )}
                </Fragment>
            )}
        </Form>
    );
};

JourneyContinuationAndAccommodationForm.propTypes = {
    service: PropTypes.object.isRequired,
};

export default JourneyContinuationAndAccommodationForm;
