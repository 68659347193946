import React, {Fragment, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {withRouter} from '@computerrock/formation-router';
import {calendarIcon, DateField, Divider, Form, InputField, useStyles} from '@ace-de/ui-components';
import {StepperContext} from '../../ui-components';
import config from '../../config';
import FormButtons from './FormButtons';
import {formStepNames} from '../constants/forms';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import {personTypes} from '../constants/person';
import {getGeolocationFromString} from '../../google/googleMapFunctions';

const MemberAndVehicleForm = props => {
    const {cx} = useStyles();
    const {memberInfo, invoiceSubmissionDraft} = props;
    const {createInvoiceSubmissionDraft, updateInvoiceSubmissionDraft, cancelInvoiceSubmissionDraft} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateForm = createTranslateShorthand('member_vehicle_form');
    const {selectStep, currentStep} = useContext(StepperContext);
    const [errors, setErrors] = useState({
        registrationDateTime: false,
    });

    const createMemberLocation = async memberAddress => {
        // if user didn't select existing case and member has no location
        // we need to provide it based on member's address details
        const {street, city, postCode} = memberAddress;
        const address = [street, postCode, city].filter(value => !!value).join(', ');

        return getGeolocationFromString(address).then(result => {
            if (result && result.address) {
                return {
                    longitude: result.location?.lng,
                    latitude: result.location?.lat,
                };
            }
            return null;
        });
    };

    const handleErrors = (field, value) => {
        setErrors(prevState => ({
            ...prevState,
            [field]: !value,
        }));
    };

    const handleOnSubmit = async formValues => {
        if (!formValues) return;
        const {vehicle} = formValues;

        const invoiceSubmissionDraftData = {
            ...(Object.keys(vehicle).length > 0 && {
                vehicle: {
                    ...vehicle,
                    ...(vehicle.registrationDateTime && {
                        registrationDateTime: moment(vehicle.registrationDateTime, ['DD.MM.YYYY', moment.ISO_8601]).toISOString(),
                    }),
                },
            }),
            member: {
                membershipNumber: memberInfo?.membershipNumber,
                name: [memberInfo?.firstname, memberInfo?.surname].filter(value => !!value).join(' '),
                type: personTypes.MEMBER,
                location: memberInfo?.location || await createMemberLocation(memberInfo?.address),
                address: memberInfo?.address,
                ...(!invoiceSubmissionDraft && {
                    phoneNumber: memberInfo?.phoneNumber || '',
                    email: memberInfo?.email || '',
                }),
            },
        };

        if (invoiceSubmissionDraft) {
            updateInvoiceSubmissionDraft({
                invoiceSubmissionDraftData,
                selectStep,
                currentStep,
            });
            return;
        }

        createInvoiceSubmissionDraft({
            invoiceSubmissionDraftData,
            selectStep,
            currentStep,
        });
    };

    const handleOnClose = async formValues => {
        let invoiceSubmissionDraftData;
        if (formValues) {
            const {vehicle} = formValues;
            invoiceSubmissionDraftData = {
                ...(Object.keys(vehicle).length > 0 && {
                    vehicle: {
                        ...vehicle,
                        ...(vehicle.registrationDateTime && {
                            registrationDateTime: moment(vehicle.registrationDateTime, ['DD.MM.YYYY', moment.ISO_8601]).toISOString(),
                        }),
                    },
                }),
            };
        }
        cancelInvoiceSubmissionDraft({invoiceSubmissionDraftData, currentStep});
    };

    const isValidationError = formValues => {
        if (!formValues) return true;
        const {vehicle} = formValues;

        return !(memberInfo?.membershipNumber
            && memberInfo?.surname
            && memberInfo?.firstname
            && vehicle?.model
            && vehicle?.manufacturer
            && vehicle?.licensePlate
            && (vehicle?.registrationDateTime
                && moment(config.minRegistrationDate, ['YYYY-MM-DD', 'DD.MM.YYYY'])
                    .isSameOrBefore(moment(vehicle.registrationDateTime, ['YYYY-MM-DD', 'DD.MM.YYYY']))
                && moment()
                    .isSameOrAfter(moment(vehicle.registrationDateTime, ['YYYY-MM-DD', 'DD.MM.YYYY']))));
    };

    return (
        <Form name="memberAndVehicle" onSubmit={handleOnSubmit}>
            {formValues => (
                <Fragment>
                    <h2 className={cx([
                        'global!ace-u-typography--variant-h2',
                        'global!ace-u-margin--small-bottom-24',
                    ])}
                    >
                        {translate('case_creation.form_step_name.member_and_vehicle_data')}
                    </h2>
                    <h3 className={cx([
                        'global!ace-u-typography--variant-h3',
                        'global!ace-u-margin--small-bottom-16',
                    ])}
                    >
                        {translateForm('title.member')}
                    </h3>
                    <Form name="member">
                        <InputField
                            name="membershipNumber"
                            label={translateForm('input_label.membership_number')}
                            className={cx([
                                'global!ace-u-full-width',
                                'global!ace-u-margin--large-bottom-32',
                                'global!ace-u-margin--small-bottom-24',
                            ])}
                            value={memberInfo?.membershipNumber || ''}
                            isDisabled={true}
                        />
                        <InputField
                            name="firstName"
                            label={translateForm('input_label.first_name')}
                            className={cx([
                                'global!ace-u-full-width',
                                'global!ace-u-margin--large-bottom-32',
                                'global!ace-u-margin--small-bottom-24',
                            ])}
                            value={memberInfo?.firstname || ''}
                            isDisabled={true}
                        />
                        <InputField
                            name="lastName"
                            label={translateForm('input_label.last_name')}
                            className={cx([
                                'global!ace-u-full-width',
                                'global!ace-u-margin--large-bottom-32',
                                'global!ace-u-margin--small-bottom-24',
                            ])}
                            value={memberInfo?.surname || ''}
                            isDisabled={true}
                        />
                    </Form>
                    <Divider className={cx([
                        'global!ace-u-margin--medium-bottom-32',
                        'global!ace-u-margin--medium-top-24',
                        'global!ace-u-display--small-none',
                        'global!ace-u-display--medium-block',
                    ])}
                    />
                    <h3 className={cx([
                        'global!ace-u-typography--variant-h3',
                        'global!ace-u-margin--small-bottom-16',
                    ])}
                    >
                        {translateForm('title.vehicle_details')}
                    </h3>
                    <Form name="vehicle">
                        <InputField
                            name="manufacturer"
                            label={translateForm('input_label.manufacturer')}
                            className={cx([
                                'global!ace-u-full-width',
                                'global!ace-u-margin--large-bottom-32',
                                'global!ace-u-margin--small-bottom-24',
                            ])}
                            value={invoiceSubmissionDraft?.vehicle?.manufacturer || ''}
                        />
                        <InputField
                            name="model"
                            label={translateForm('input_label.model')}
                            className={cx([
                                'global!ace-u-full-width',
                                'global!ace-u-margin--large-bottom-32',
                                'global!ace-u-margin--small-bottom-24',
                            ])}
                            value={invoiceSubmissionDraft?.vehicle?.model || ''}
                        />
                        <InputField
                            name="licensePlate"
                            label={translateForm('input_label.license_plate')}
                            className={cx([
                                'global!ace-u-full-width',
                                'global!ace-u-margin--large-bottom-32',
                                'global!ace-u-margin--small-bottom-24',
                            ])}
                            value={invoiceSubmissionDraft?.vehicle?.licensePlate || ''}
                        />
                        <DateField
                            name="registrationDateTime"
                            label={translateForm('input_label.registration_date')}
                            icon={calendarIcon}
                            format="DD.MM.YYYY"
                            className={cx([
                                'global!ace-u-full-width',
                                'global!ace-u-margin--large-bottom-32',

                            ])}
                            value={invoiceSubmissionDraft?.vehicle?.registrationDateTime || ''}
                            direction="top"
                            isFieldMandatory={true}
                            maxDate={moment().format()}
                            minDate={moment(config.minRegistrationDate, 'DD.MM.YYYY').format()}
                            onBlur={value => handleErrors('registrationDateTime', value)}
                            errors={errors.registrationDateTime ? [translate('global.error.empty_mandatory_field')] : []}
                        />
                    </Form>
                    <FormButtons
                        formName={formStepNames.MEMBER_AND_VEHICLE_DATA}
                        isSubmitDisabled={isValidationError(formValues?.vehicle
                            && Object.keys(formValues.vehicle).length > 0
                            ? formValues
                            : invoiceSubmissionDraft)}
                        handleOnClose={() => handleOnClose(formValues)}
                    />
                </Fragment>
            )}
        </Form>
    );
};

MemberAndVehicleForm.propTypes = {
    memberInfo: PropTypes.object,
    invoiceSubmissionDraft: PropTypes.object,
    createInvoiceSubmissionDraft: PropTypes.func.isRequired,
    updateInvoiceSubmissionDraft: PropTypes.func.isRequired,
    cancelInvoiceSubmissionDraft: PropTypes.func.isRequired,
};

MemberAndVehicleForm.defaultProps = {
    memberInfo: null,
    invoiceSubmissionDraft: null,
};

const mapStateToProps = state => {
    return {
        memberInfo: state.member.memberInfo,
        invoiceSubmissionDraft: state.invoiceSubmissions.invoiceSubmissionDraft,
    };
};

const mapDispatchToProps = dispatch => ({
    createInvoiceSubmissionDraft: payload => dispatch({
        type: invoiceSubmissionActionTypes.CREATE_INVOICE_SUBMISSION_DRAFT,
        payload,
    }),
    updateInvoiceSubmissionDraft: payload => dispatch({
        type: invoiceSubmissionActionTypes.UPDATE_INVOICE_SUBMISSION_DRAFT,
        payload,
    }),
    cancelInvoiceSubmissionDraft: payload => dispatch({
        type: invoiceSubmissionActionTypes.INITIATE_INVOICE_SUBMISSION_CANCEL_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberAndVehicleForm));
