import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useTranslate} from '@computerrock/formation-i18n';
import {Form, SelectField, Option, DateField, InputField, TextAreaField, ToggleSwitch, calendarIcon, InputCurrencyField, useStyles} from '@ace-de/ui-components';
import {Info} from '../../ui-components';
import {aidServices} from '../constants/aid';
import {personTypes} from '../constants/person';
import {purposes} from '../constants/purpose';
import SearchAutocomplete from '../../google/ui-components/SearchAutocomplete';
import {getGeolocationFromString} from '../../google/googleMapFunctions';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import {priceTypes} from '../../mia-entities';

const AidForm = props => {
    const {cx} = useStyles();
    const {service, member, aidServicePrice, fetchAidServiceMaxPrice, serviceCase} = props;
    const {createTranslateShorthand, translate, activeLocale} = useTranslate();
    const translateForm = createTranslateShorthand('aid_form');
    const translateInvoiceForm = createTranslateShorthand('invoice_submission_global');
    const [errors, setErrors] = useState({
        type: false,
        requestedInvoicingAmount: false,
        requestedInvoicingAmountRange: false,
        damageDate: false,
        driverType: false,
        driverFirstName: false,
        driverLastName: false,
        driverAddressStreet: false,
        driverAddressPostCode: false,
        driverAddressCity: false,
        ownerType: false,
        ownerFirstName: false,
        ownerLastName: false,
        ownerAddressStreet: false,
        ownerAddressPostCode: false,
        ownerAddressCity: false,
        tripPurpose: false,
        damageLocation: false,
        serviceProvider: false,
    });
    const [damageLocation, setDamageLocation] = useState(service?.damage?.address);
    const [requestedInvoicingAmount, setRequestedInvoicingAmount] = useState(service?.requestedInvoicingAmount || '');

    useEffect(() => {
        if (!service || !service.type || !service.damage?.date) return;
        fetchAidServiceMaxPrice({
            validOn: moment(service.damage.date).format('YYYY-MM-DD'),
            aidServiceType: service.type,
        });
    }, [service, fetchAidServiceMaxPrice]);

    const validateAutocompleteAddress = (field, value) => {
        setErrors(prevState => ({
            ...prevState,
            [field]: !value,
        }));
        getGeolocationFromString(value).then(result => {
            let address = null;
            if (result && result.address) {
                address = {
                    street: result.address.street || '',
                    postCode: result.address.postCode,
                    city: result.address.city,
                    country: result.address.country,
                    formattedAddress: result.address.formattedAddress,
                    location: {
                        longitude: result.location?.lng,
                        latitude: result.location?.lat,
                    },
                };
                setDamageLocation(address);
                return;
            }
            setDamageLocation(address);
        });
    };

    const handleErrors = (field, value) => {
        if (!field) return;
        setErrors(prevState => ({
            ...prevState,
            [field]: field === 'damageLocation'
                ? !damageLocation
                : !value,
            ...(field === 'requestedInvoicingAmount' && {
                'requestedInvoicingAmountRange': value > aidServicePrice,
            }),
        }));
    };

    const handleFetchAidMaxPrice = (formValues, field, value) => {
        if (!formValues) return;
        const {damageDate, type} = formValues;
        if (!value || (!type && field !== 'type')
            || (field === 'damageDate' && !moment(value, ['DD.MM.YYYY', 'D.M.YYYY', moment.ISO_8601], true).isValid())) return;

        fetchAidServiceMaxPrice({
            ...(field === 'damageDate'
                ? {validOn: moment(value).format('YYYY-MM-DD')}
                : {validOn: damageDate
                    ? moment(damageDate).format('YYYY-MM-DD')
                    : moment().format('YYYY-MM-DD')}),
            ...(field === 'type' ? {aidServiceType: value} : {aidServiceType: type}),
            type: priceTypes.AID_SERVICE_MAX_PRICE,
        });
    };

    const isDriverAddressAsMember = service?.driver?.address && Object.keys(service?.driver.address).length > 0
        && (service?.driver.address.street === member?.address?.street
            && service?.driver.address.city === member?.address?.city
            && service?.driver.address.postCode === member?.address?.postCode);
    const isOwnerAddressAsMember = service?.owner?.address && Object.keys(service?.owner.address).length > 0
        && (service?.owner.address.street === member?.address?.street
            && service?.owner.address.city === member?.address?.city
            && service?.owner.address.postCode === member?.address?.postCode);

    return (
        <Form name="aid">
            {formValues => (
                <Fragment>
                    <h3
                        className={cx([
                            'global!ace-u-typography--variant-h3',
                            'global!ace-u-margin--small-bottom-24',
                        ])}
                    >
                        {translateForm('title.select_aid')}
                    </h3>
                    <SelectField
                        name="type"
                        label={translateForm('select_label.service_type')}
                        className={cx([
                            'global!ace-u-full-width',
                            'global!ace-u-margin--small-bottom-32',
                        ])}
                        value={service?.type || ''}
                        placeholder={translateForm('select_placeholder.service_type')}
                        onBlur={value => {
                            handleErrors('type', value);
                            handleFetchAidMaxPrice(formValues, 'type', value);
                        }}
                        errors={errors.type ? [translate('global.error.empty_mandatory_field')] : []}
                    >
                        {Object.keys(aidServices)
                            .filter(aidService => ((serviceCase && serviceCase.emergencyCallDateTime.year() < 2025)
                                || (!serviceCase && moment().year() < 2025)
                                ? aidService !== aidServices.ELEMENTAL_DAMAGE : true))
                            .map(aidService => {
                                return (
                                    <Option
                                        name={`${aidService}Option`}
                                        key={aidService}
                                        value={aidService}
                                    >
                                        {translate(`global.aid_service.${aidService.toLowerCase()}`)}
                                    </Option>
                                );
                            })}
                    </SelectField>
                    {formValues?.type && (
                        <Fragment>
                            <h3
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--small-bottom-16',
                                ])}
                            >
                                {translateInvoiceForm('title.total_amount')}
                            </h3>
                            <InputCurrencyField
                                placeholder={translateInvoiceForm('input_placeholder.requested_invoicing_amount')}
                                name="requestedInvoicingAmount"
                                label={translateInvoiceForm('input_label.requested_invoicing_amount')}
                                className={cx([
                                    'global!ace-u-full-width',
                                ], {
                                    'global!ace-u-margin--small-bottom-24': !errors.requestedInvoicingAmountRange,
                                })}
                                value={requestedInvoicingAmount}
                                onChange={value => setRequestedInvoicingAmount(value)}
                                type="number"
                                onBlur={value => {
                                    handleErrors('requestedInvoicingAmount', value);
                                    if (value && Number(value) > aidServicePrice) {
                                        setRequestedInvoicingAmount(aidServicePrice.toFixed(2));
                                        return;
                                    }
                                    setRequestedInvoicingAmount(Number(value).toFixed(2));
                                }}
                                errors={errors.requestedInvoicingAmount ? [translate('global.error.invalid_entry')]
                                    : (errors.requestedInvoicingAmountRange ? [''] : [])}
                                isValueFormatted={true}
                            />
                            {errors.requestedInvoicingAmountRange && (
                                <Info
                                    className={cx([
                                        'global!ace-u-margin--top-16',
                                        'global!ace-u-margin--small-bottom-24',
                                    ])}
                                    isWarning
                                >
                                    {translateForm(`error.invalid_requested_invoicing_amount_${formValues.type.toLowerCase()}`, {
                                        aidServicePrice: aidServicePrice.toLocaleString(activeLocale, {style: 'currency', currency: 'EUR'}),
                                    })}
                                </Info>
                            )}
                            <h3
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--small-bottom-16',
                                ])}
                            >
                                {translateForm('title.about_driver')}
                            </h3>
                            <SelectField
                                name="driverType"
                                label={translateForm('select_label.driver_type')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--small-bottom-32',
                                ])}
                                value={service.driver?.type || ''}
                                placeholder={translateForm('select_placeholder.driver_type')}
                                onBlur={value => handleErrors('driverType', value)}
                                errors={errors.driverType ? [translate('global.error.empty_mandatory_field')] : []}
                            >
                                {Object.keys(personTypes).map(personType => {
                                    return (
                                        <Option
                                            name={`${personType}Option`}
                                            key={personType}
                                            value={personType}
                                        >
                                            {translate(`global.person_type.${personType.toLowerCase()}`)}
                                        </Option>
                                    );
                                })}
                            </SelectField>
                            {!!formValues.driverType && formValues.driverType !== personTypes.MEMBER && (
                                <Fragment>
                                    <InputField
                                        placeholder={translateForm('input_placeholder.driver_first_name')}
                                        name="driverFirstName"
                                        label={translateForm('input_label.driver_first_name')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--large-bottom-32',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                        value={service.driver?.name || ''}
                                        onBlur={value => handleErrors('driverFirstName', value)}
                                        errors={errors.driverFirstName ? [translate('global.error.empty_mandatory_field')] : []}
                                    />
                                    <InputField
                                        placeholder={translateForm('input_placeholder.driver_last_name')}
                                        name="driverLastName"
                                        label={translateForm('input_label.driver_last_name')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--large-bottom-32',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                        value={service.driver?.surname || ''}
                                        onBlur={value => handleErrors('driverLastName', value)}
                                        errors={errors.driverLastName ? [translate('global.error.empty_mandatory_field')] : []}
                                    />
                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                    >
                                        <p className={cx(['global!ace-u-flex--small-grow-1', 'global!ace-u-margin--small-right-24'])}>
                                            {translateForm('toggle_switch_label.driver_address_as_member')}
                                        </p>
                                        <ToggleSwitch
                                            name="isDriverAddressAsMember"
                                            value={true}
                                            isSelected={isDriverAddressAsMember}
                                        />
                                    </div>
                                    {!formValues?.isDriverAddressAsMember && (
                                        <Fragment>
                                            <InputField
                                                placeholder={translateForm('input_placeholder.driver_address_street')}
                                                name="driverAddressStreet"
                                                label={translateForm('input_label.driver_address_street')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--large-bottom-32',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.driver?.address?.street || ''}
                                                onBlur={value => handleErrors('driverAddressStreet', value)}
                                                errors={errors.driverAddressStreet ? [translate('global.error.empty_location')] : []}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.driver_address_post_code')}
                                                name="driverAddressPostCode"
                                                label={translateForm('input_label.driver_address_post_code')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--large-bottom-32',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.driver?.address?.postCode || ''}
                                                onBlur={value => handleErrors('driverAddressPostCode', value)}
                                                errors={errors.driverAddressPostCode ? [translate('global.error.empty_location')] : []}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.driver_address_city')}
                                                name="driverAddressCity"
                                                label={translateForm('input_label.driver_address_city')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--large-bottom-32',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.driver?.address?.city || ''}
                                                onBlur={value => handleErrors('driverAddressCity', value)}
                                                errors={errors.driverAddressCity ? [translate('global.error.empty_location')] : []}
                                            />
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                            <div className={cx('global!ace-u-margin--small-bottom-8')} />
                            <h3
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--small-bottom-16',
                                ])}
                            >
                                {translateForm('title.about_owner')}
                            </h3>
                            <SelectField
                                name="ownerType"
                                label={translateForm('select_label.owner_type')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--small-bottom-32',
                                ])}
                                value={service?.owner?.type || ''}
                                placeholder={translateForm('select_placeholder.owner_type')}
                                onBlur={value => handleErrors('ownerType', value)}
                                errors={errors.ownerType ? [translate('global.error.empty_mandatory_field')] : []}
                            >
                                {Object.keys(personTypes).map(personType => {
                                    return (
                                        <Option
                                            key={personType}
                                            name={`${personType}Option`}
                                            value={personType}
                                        >
                                            {translate(`global.person_type.${personType.toLowerCase()}`)}
                                        </Option>
                                    );
                                })}
                            </SelectField>
                            {!!formValues.ownerType && formValues.ownerType !== personTypes.MEMBER && (
                                <Fragment>
                                    <InputField
                                        placeholder={translateForm('input_placeholder.owner_first_name')}
                                        name="ownerFirstName"
                                        label={translateForm('input_label.owner_first_name')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--large-bottom-32',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                        value={service?.owner?.name || ''}
                                        onBlur={value => handleErrors('ownerFirstName', value)}
                                        errors={errors.ownerFirstName ? [translate('global.error.empty_mandatory_field')] : []}
                                    />

                                    <InputField
                                        placeholder={translateForm('input_placeholder.owner_last_name')}
                                        name="ownerLastName"
                                        label={translateForm('input_label.owner_last_name')}
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-margin--large-bottom-32',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                        value={service?.owner?.surname || ''}
                                        onBlur={value => handleErrors('ownerLastName', value)}
                                        errors={errors.ownerLastName ? [translate('global.error.empty_mandatory_field')] : []}
                                    />

                                    <div
                                        className={cx([
                                            'global!ace-u-flex',
                                            'global!ace-u-margin--small-bottom-24',
                                        ])}
                                    >
                                        <p className={cx(['global!ace-u-flex--small-grow-1', 'global!ace-u-margin--small-right-24'])}>
                                            {translateForm('toggle_switch_label.owner_address_as_member')}
                                        </p>
                                        <ToggleSwitch
                                            name="isOwnerAddressAsMember"
                                            value={true}
                                            isSelected={isOwnerAddressAsMember}
                                        />
                                    </div>
                                    {!formValues?.isOwnerAddressAsMember && (
                                        <Fragment>
                                            <InputField
                                                placeholder={translateForm('input_placeholder.owner_address_street')}
                                                name="ownerAddressStreet"
                                                label={translateForm('input_label.owner_address_street')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--large-bottom-32',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.owner?.address?.street || ''}
                                                onBlur={value => handleErrors('ownerAddressStreet', value)}
                                                errors={errors.ownerAddressStreet ? [translate('global.error.empty_location')] : []}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.owner_address_post_code')}
                                                name="ownerAddressPostCode"
                                                label={translateForm('input_label.owner_address_post_code')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--large-bottom-32',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service?.owner?.address?.postCode || ''}
                                                onBlur={value => handleErrors('ownerAddressPostCode', value)}
                                                errors={errors.ownerAddressPostCode ? [translate('global.error.empty_location')] : []}
                                            />
                                            <InputField
                                                placeholder={translateForm('input_placeholder.owner_address_city')}
                                                name="ownerAddressCity"
                                                label={translateForm('input_label.owner_address_city')}
                                                className={cx([
                                                    'global!ace-u-full-width',
                                                    'global!ace-u-margin--large-bottom-32',
                                                    'global!ace-u-margin--small-bottom-24',
                                                ])}
                                                value={service.owner?.address.city || ''}
                                                onBlur={value => handleErrors('ownerAddressCity', value)}
                                                errors={errors.ownerAddressCity ? [translate('global.error.empty_location')] : []}
                                            />
                                        </Fragment>
                                    )}
                                </Fragment>
                            )}
                            <h3
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--small-bottom-16',
                                ])}
                            >
                                {translateForm('title.case_data')}
                            </h3>
                            <SelectField
                                name="tripPurpose"
                                label={translateForm('select_label.trip_purpose')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--small-bottom-32',
                                ])}
                                value={service.tripPurpose || ''}
                                placeholder={translateForm('select_placeholder.trip_purpose')}
                                onBlur={value => handleErrors('tripPurpose', value)}
                                errors={errors.tripPurpose ? [translate('global.error.empty_mandatory_field')] : []}
                            >
                                {Object.keys(purposes).map(purpose => {
                                    return (
                                        <Option
                                            key={purpose}
                                            name={`${purpose}Option`}
                                            value={purpose}
                                        >
                                            {translate(`global.purpose.${purpose.toLowerCase()}`)}
                                        </Option>
                                    );
                                })}
                            </SelectField>
                            <div className={cx('global!ace-u-margin--small-bottom-8')} />
                            <SearchAutocomplete
                                placeholder={translateForm('input_placeholder.damage_location')}
                                name="formattedDamageLocation"
                                label={translateForm('input_label.damage_location')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--large-bottom-32',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                                value={formValues.hasOwnProperty('formattedDamageLocation')
                                    ? formValues?.formattedDamageLocation
                                    : service?.damage?.address?.formattedAddress
                                    || (service?.damage?.address
                                        ? Object.keys(service?.damage.address)
                                            .filter(key => key !== 'country' && key !== 'location' && key !== 'persistenceState')
                                            .map(key => (service?.damage.address[key]))
                                            .filter(value => !!value)
                                            .join(' ')
                                        : '')
                                }
                                onInputBlur={value => handleErrors('damageLocation', value)}
                                onChange={() => setDamageLocation(null)}
                                onOptionSelect={value => validateAutocompleteAddress('damageLocation', value)}
                                errors={errors.damageLocation ? [translate('global.error.empty_location')] : []}
                            />
                            <InputField
                                name="damageLocation"
                                type="hidden"
                                value={damageLocation || ''}
                            />
                            <DateField
                                name="damageDate"
                                label={translateForm('date_field_label.damage_date')}
                                icon={calendarIcon}
                                format="DD.MM.YYYY"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--large-bottom-32',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                                value={service?.damage?.date || ''}
                                onChange={value => handleFetchAidMaxPrice(formValues, 'damageDate', value)}
                                maxDate={moment().format()}
                                onBlur={value => handleErrors('damageDate', value)}
                                errors={errors.damageDate ? [translate('global.error.empty_mandatory_field')] : []}
                            />
                            <InputField
                                placeholder={translateForm('input_placeholder.service_provider')}
                                name="serviceProvider"
                                label={translateForm('input_label.service_provider')}
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--large-bottom-32',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                                value={service?.serviceProvider || ''}
                                onBlur={value => handleErrors('serviceProvider', value)}
                                errors={errors.serviceProvider ? [translate('global.error.empty_mandatory_field')] : []}
                            />
                            <DateField
                                name="endOfRecoveryDateTime"
                                label={translateForm('date_field_label.end_of_recovery_date_time')}
                                icon={calendarIcon}
                                format="DD.MM.YYYY"
                                className={cx([
                                    'global!ace-u-full-width',
                                    'global!ace-u-margin--large-bottom-32',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                                value={service?.endOfRecoveryDateTime || ''}
                                isFieldMandatory={false}
                                minDate={formValues?.damageDate
                                    ? moment(formValues.damageDate, ['YYYY-MM-DD', 'DD.MM.YYYY']).format()
                                    : ''
                            }
                                errors={formValues?.damageDate && formValues?.endOfRecoveryDateTime
                                && moment(formValues.damageDate, ['YYYY-MM-DD', 'DD.MM.YYYY'])
                                    .isAfter(moment(formValues.endOfRecoveryDateTime, ['YYYY-MM-DD', 'DD.MM.YYYY']))
                                    ? [translate('global.error.invalid_date')]
                                    : []
                                }
                            />
                            <div className={cx('global!ace-u-margin--small-bottom-8')} />
                            <h3
                                className={cx([
                                    'global!ace-u-typography--variant-h3',
                                    'global!ace-u-margin--small-bottom-24',
                                ])}
                            >
                                {translateForm('title.damage_description')}
                            </h3>
                            <TextAreaField
                                placeholder={translateForm('text_area_placeholder.aid_comment')}
                                name="comment"
                                value={service?.comment || ''}
                                maxLength={250}
                                isResizable={false}
                                className={cx([
                                    'ace-c-text-area--small',
                                    'global!ace-u-margin--small-bottom-24',
                                    'global!ace-u-full-width',
                                ])}
                                label={translateForm('text_area_label.aid_comment')}
                            />
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Form>
    );
};

AidForm.propTypes = {
    service: PropTypes.object.isRequired,
    member: PropTypes.object.isRequired,
    aidServicePrice: PropTypes.number,
    fetchAidServiceMaxPrice: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
};

AidForm.defaultProps = {
    aidServicePrice: 0,
    serviceCase: null,
};

const mapStateToProps = state => ({
    aidServicePrice: state.invoiceSubmissions.aidServicePrice,
});

const mapDispatchToProps = dispatch => ({
    fetchAidServiceMaxPrice: payload => dispatch({
        type: invoiceSubmissionActionTypes.FETCH_AID_SERVICE_MAX_PRICE,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AidForm);
