import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {Form, TextAreaField, InputCurrencyField, useStyles} from '@ace-de/ui-components';

const OtherForm = props => {
    const {cx} = useStyles();
    const {service} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateForm = createTranslateShorthand('other_form');
    const translateInvoiceForm = createTranslateShorthand('invoice_submission_global');
    const [errors, setErrors] = useState({
        comment: false,
        requestedInvoicingAmount: false,
    });

    const handleErrors = (field, value) => {
        if (!field) return;
        setErrors(prevState => ({
            ...prevState,
            [field]: !value,
        }));
    };

    return (
        <Form name="other">
            <h3
                className={cx([
                    'global!ace-u-typography--variant-h3',
                    'global!ace-u-margin--small-bottom-16',
                ])}
            >
                {translateInvoiceForm('title.total_amount')}
            </h3>
            <InputCurrencyField
                placeholder={translateInvoiceForm('input_placeholder.requested_invoicing_amount')}
                name="requestedInvoicingAmount"
                label={translateInvoiceForm('input_label.requested_invoicing_amount')}
                className={cx([
                    'global!ace-u-full-width',
                    'global!ace-u-margin--small-bottom-24',
                ])}
                value={service?.requestedInvoicingAmount || ''}
                type="number"
                onBlur={value => handleErrors('requestedInvoicingAmount', value)}
                errors={errors.requestedInvoicingAmount ? [translate('global.error.invalid_entry')] : []}
            />
            <h3
                className={cx([
                    'global!ace-u-typography--variant-h3',
                    'global!ace-u-margin--small-bottom-24',
                ])}
            >
                {translateForm('title.damage_description')}
            </h3>
            <TextAreaField
                placeholder={translateForm('text_area_placeholder.other_comment')}
                name="comment"
                value={service?.comment || ''}
                maxLength={250}
                isResizable={false}
                className={cx([
                    'ace-c-text-area--small',
                    'global!ace-u-margin--small-bottom-24',
                    'global!ace-u-full-width',
                ])}
                label={translateForm('text_area_label.other_comment')}
                onBlur={value => handleErrors('comment', value)}
                errors={errors.comment ? [translate('global.error.invalid_entry')] : []}
            />
        </Form>
    );
};

OtherForm.propTypes = {
    service: PropTypes.object.isRequired,
};

export default OtherForm;
