import React, {useContext, Fragment, useMemo, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {useParams, withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {Form, RadioButtonGroup, RadioTile, useStyles} from '@ace-de/ui-components';
import {StepperContext} from '../../ui-components';
import {categories, categoryIcons} from '../constants/categories';
import RoadsideAssistanceAndTowingForm from './RoadsideAssistanceAndTowingForm';
import JourneyContinuationAndAccommodationForm from './JourneyContinuationAndAccommodationForm';
import AidForm from './AidForm';
import OtherForm from './OtherForm';
import FormButtons from './FormButtons';
import {formStepNames} from '../constants/forms';
import * as invoiceSubmissionActionTypes from '../invoiceSubmissionActionTypes';
import {serviceTypes} from '../../mia-entities';
import {isAidFormError, isJourneyContinuationAndAccommodationError, isOtherFormError, isTowingAndRoadsideAssistanceValidationError} from '../utils/validation';
import {mapFormValuesToServiceFields, mapServiceToFormFields} from '../utils/mappers';

const getServiceCategory = type => {
    if (!type) return null;
    switch (type) {
        case serviceTypes.BROKEN_GLASS:
        case serviceTypes.MARTEN_BITE:
        case serviceTypes.ROADKILL:
        case serviceTypes.ELEMENTAL_DAMAGE: {
            return categories.AID;
        }
        case serviceTypes.ACCOMMODATION:
        case serviceTypes.FURTHER_JOURNEY:
        case serviceTypes.RENTAL_CAR:
        case serviceTypes.SHORT_TRIP: {
            return categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION;
        }
        case serviceTypes.OTHER: {
            return categories.OTHER;
        }
        case serviceTypes.ROADSIDE_ASSISTANCE:
        case serviceTypes.TOWING:
        case serviceTypes.VEHICLE_OPENING: {
            return categories.ROADSIDE_ASSISTANCE_AND_TOWING;
        }
    }
};

const ServiceCategoryDetailsForm = props => {
    const {cx} = useStyles();
    const params = useParams();
    const isFromExistingCase = !!params.serviceCaseId;
    const {invoiceSubmissionDraft, initiateAddInvoiceSubmissionServiceFlow, aidServicePrice} = props;
    const {cancelInvoiceSubmissionDraft, initiateDeleteInvoiceSubmissionServiceFlow, serviceCase} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateForm = createTranslateShorthand('service_category_details_form');
    const {selectStep, currentStep} = useContext(StepperContext);
    const currentServiceIndex = currentStep - (isFromExistingCase ? 1 : 2);
    const service = useMemo(() => (
        invoiceSubmissionDraft?.services?.length > 0
            ? invoiceSubmissionDraft.services[currentServiceIndex]
            : {}
    ), [invoiceSubmissionDraft?.services, currentServiceIndex]);
    const [serviceCategory, setServiceCategory] = useState();

    useEffect(() => {
        setServiceCategory(getServiceCategory(service?.type) || '');
    }, [service]);

    if (!invoiceSubmissionDraft) return null;

    const isValidationError = formValues => {
        if (!formValues?.category) {
            return true;
        }

        switch (formValues.category) {
            case categories.ROADSIDE_ASSISTANCE_AND_TOWING: {
                const {roadsideAssistanceAndTowing: serviceForm} = formValues;

                return isTowingAndRoadsideAssistanceValidationError(serviceForm && Object.keys(serviceForm).length > 0
                    ? serviceForm
                    : mapServiceToFormFields(service, invoiceSubmissionDraft.member));
            }
            case categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION: {
                const {journeyContinuationAndAccommodation: serviceForm} = formValues;
                return isJourneyContinuationAndAccommodationError({
                    ...mapServiceToFormFields(service, invoiceSubmissionDraft.member),
                    ...(serviceForm && Object.keys(serviceForm).length > 0 && serviceForm),
                });
            }
            case categories.AID: {
                const {aid: serviceForm} = formValues;
                return isAidFormError(serviceForm && Object.keys(serviceForm).length > 0
                    ? serviceForm
                    : mapServiceToFormFields(service, invoiceSubmissionDraft.member), aidServicePrice);
            }
            case categories.OTHER: {
                const {other: serviceForm} = formValues;
                return isOtherFormError(serviceForm && Object.keys(serviceForm).length > 0
                    ? serviceForm
                    : mapServiceToFormFields(service, invoiceSubmissionDraft.member));
            }
            default:
                // no-op
        }
    };

    const handleOnSubmit = formValues => {
        const serviceForm = mapFormValuesToServiceFields(formValues, invoiceSubmissionDraft.member);

        initiateAddInvoiceSubmissionServiceFlow({
            invoiceSubmissionDraftServiceData: {
                ...service,
                ...(serviceForm && Object.values(serviceForm).length > 0
                    && {
                        ...serviceForm,
                        category: formValues.category,
                        ...(serviceForm.startDateTime && {
                            startDateTime: moment(serviceForm.startDateTime, ['DD.MM.YYYY', moment.ISO_8601]).toISOString(),
                        }),
                        ...(serviceForm.endDateTime && {
                            endDateTime: moment(serviceForm.endDateTime, ['DD.MM.YYYY', moment.ISO_8601]).toISOString(),
                        }),
                    }),
            },
            selectStep,
            currentStep,
            currentServiceIndex: currentServiceIndex,
        });
    };

    const handleOnClose = formValues => {
        const serviceForm = mapFormValuesToServiceFields(formValues, invoiceSubmissionDraft.member);
        const invoiceSubmissionDraftServiceData = {
            ...service,
            ...(serviceForm && Object.values(serviceForm).length > 0
                && {
                    ...serviceForm,
                    category: formValues.category,
                    ...(serviceForm.startDateTime && {
                        startDateTime: moment(serviceForm.startDateTime, ['DD.MM.YYYY', moment.ISO_8601]).toISOString(),
                    }),
                    ...(serviceForm.endDateTime && {
                        endDateTime: moment(serviceForm.endDateTime, ['DD.MM.YYYY', moment.ISO_8601]).toISOString(),
                    }),
                }),
        };
        const currentServiceIndex = currentStep - (isFromExistingCase ? 1 : 2);
        const existingService = invoiceSubmissionDraft.services.length > 0
            ? invoiceSubmissionDraft.services[currentServiceIndex]
            : null;
        const services = invoiceSubmissionDraft.services?.map(service => service);
        existingService
            ? services[currentServiceIndex] = invoiceSubmissionDraftServiceData
            : services.push(invoiceSubmissionDraftServiceData);
        const servicesWithType = services.filter(service => !!service.type);
        cancelInvoiceSubmissionDraft({
            invoiceSubmissionDraftData: {
                ...(servicesWithType.length > 0 && {services: servicesWithType}),
            },
            // if the user adds a service but does not choose a service type,
            // on cancel, we will not save that additional empty service,
            // so we have to decrement the currentStep
            currentStep: invoiceSubmissionDraftServiceData?.type ? currentStep : currentStep - 1,
        });
    };

    const labelRadioTileTextAid = (serviceCase && serviceCase.emergencyCallDateTime.year() < 2025)
        || (!serviceCase && moment().year() < 2025)
        ? translateForm('radio_tile_text.aid_2024') : translateForm('radio_tile_text.aid');

    return (
        <Form name="serviceCategoryDetailsForm" onSubmit={handleOnSubmit}>
            {formValues => (
                <Fragment>
                    <h2
                        className={cx([
                            'global!ace-u-typography--variant-h2',
                            'global!ace-u-margin--small-bottom-24',
                        ])}
                    >
                        {translateForm('title.which_performance_category')}
                    </h2>
                    <div
                        className={cx([
                            'global!ace-u-grid',
                            'global!ace-u-margin--small-bottom-24',
                            'global!ace-u-grid-auto-rows-1fr',
                        ])}
                    >
                        <RadioButtonGroup
                            name="category"
                            value={serviceCategory}
                            onChange={value => setServiceCategory(value)}
                        >
                            {Object.keys(categories).map((category, idx) => {
                                return (
                                    <RadioTile
                                        key={category}
                                        name={`category-option-${idx}`}
                                        icon={categoryIcons[category]}
                                        title={translate(`global.service_category.${category.toLowerCase()}`)}
                                        text={category === categories.AID ? labelRadioTileTextAid : ''}
                                        value={category}
                                        className={cx([
                                            'ace-c-radio-tile--category-tile',
                                            'global!ace-u-grid-column--small-span-6',
                                            'global!ace-u-grid-column--large-span-3',
                                        ])}
                                    />
                                );
                            })}
                        </RadioButtonGroup>
                    </div>
                    {formValues?.category === categories.ROADSIDE_ASSISTANCE_AND_TOWING && (
                        <RoadsideAssistanceAndTowingForm
                            service={service}
                            member={invoiceSubmissionDraft.member || {}}
                        />
                    )}
                    {formValues?.category === categories.JOURNEY_CONTINUATION_AND_ACCOMMODATION && (
                        <JourneyContinuationAndAccommodationForm
                            service={service}
                        />
                    )}
                    {formValues?.category === categories.AID && (
                        <AidForm
                            service={service}
                            member={invoiceSubmissionDraft.member || {}}
                            serviceCase={serviceCase}
                        />
                    )}
                    {formValues?.category === categories.OTHER && (
                        <OtherForm
                            service={service}
                        />
                    )}
                    <FormButtons
                        formName={formStepNames.SERVICE_CATEGORY_DETAILS}
                        isAdditionalService={currentServiceIndex > 0}
                        isSubmitDisabled={isValidationError(formValues)}
                        handleOnClose={() => handleOnClose(formValues)}
                        handleOnServiceDeletion={() => initiateDeleteInvoiceSubmissionServiceFlow({
                            serviceIndex: currentServiceIndex,
                            selectStep,
                            step: currentStep,
                        })}
                    />
                </Fragment>
            )}
        </Form>
    );
};

ServiceCategoryDetailsForm.propTypes = {
    invoiceSubmissionDraft: PropTypes.object,
    initiateAddInvoiceSubmissionServiceFlow: PropTypes.func.isRequired,
    initiateDeleteInvoiceSubmissionServiceFlow: PropTypes.func.isRequired,
    aidServicePrice: PropTypes.number,
    cancelInvoiceSubmissionDraft: PropTypes.func.isRequired,
    serviceCase: PropTypes.object,
};

ServiceCategoryDetailsForm.defaultProps = {
    invoiceSubmissionDraft: null,
    serviceCase: null,
    aidServicePrice: 0,
};

const mapStateToProps = state => {
    return {
        invoiceSubmissionDraft: state.invoiceSubmissions.invoiceSubmissionDraft,
        aidServicePrice: state.invoiceSubmissions.aidServicePrice,
    };
};

const mapDispatchToProps = dispatch => ({
    initiateAddInvoiceSubmissionServiceFlow: payload => dispatch({
        type: invoiceSubmissionActionTypes.INITIATE_ADD_INVOICE_SUBMISSION_SERVICE_FLOW,
        payload,
    }),
    cancelInvoiceSubmissionDraft: payload => dispatch({
        type: invoiceSubmissionActionTypes.INITIATE_INVOICE_SUBMISSION_CANCEL_FLOW,
        payload,
    }),
    initiateDeleteInvoiceSubmissionServiceFlow: payload => dispatch({
        type: invoiceSubmissionActionTypes.INITIATE_DELETE_INVOICE_SUBMISSION_SERVICE_FLOW,
        payload,
    }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServiceCategoryDetailsForm));
