import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

const ScrollToTop = ({className}) => {
    useEffect(() => {
        const element = document.querySelector(`.${className}`);

        if (!element) return;

        element.scrollIntoView({
            behavior: 'smooth',
        });
    }, [className]);
    return (
        <></>
    );
};

ScrollToTop.propTypes = {
    className: PropTypes.string,
};

ScrollToTop.defaultProps = {
    className: '',
};

export default ScrollToTop;
