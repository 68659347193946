import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslate} from '@computerrock/formation-i18n';
import {calendarIcon, ContentBlock, ContentItem, DateField, Form, InputField, TextAreaField, InputCounter, useStyles} from '@ace-de/ui-components';

const AccommodationSubForm = props => {
    const {cx} = useStyles();
    const {service} = props;
    const {createTranslateShorthand, translate} = useTranslate();
    const translateForm = createTranslateShorthand('accommodation_form');
    const [errors, setErrors] = useState({
        numberOfPeople: false,
        accommodationName: false,
        startDateTime: false,
        endDateTime: false,
    });

    const handleErrors = (field, value) => {
        setErrors(prevState => ({
            ...prevState,
            [field]: !value,
        }));
    };
    return (
        <Form name="accommodation">
            <h3
                className={cx([
                    'global!ace-u-typography--variant-h3',
                    'global!ace-u-margin--small-bottom-24',
                ])}
            >
                {translateForm('title.add_details')}
            </h3>
            <p
                className={cx([
                    'global!ace-u-typography--variant-body-medium',
                    'global!ace-u-margin--small-bottom-16',
                ])}
            >
                {translateForm('title.period_of_accommodation')}
            </p>
            <ContentBlock className={cx('global!ace-u-margin--small-bottom-24')}>
                <ContentItem className={cx('ace-c-content-item--base-span-6')}>
                    <DateField
                        name="startDateTime"
                        label={translateForm('date_field_label.stay_start_date')}
                        icon={calendarIcon}
                        format="DD.MM.YYYY"
                        className={cx('global!ace-u-full-width')}
                        value={service?.startDateTime || ''}
                        onBlur={value => handleErrors('startDateTime', value)}
                        errors={errors.startDateTime ? [translate('global.error.empty_mandatory_field')] : []}
                    />
                </ContentItem>
                <ContentItem className={cx('ace-c-content-item--base-span-6')}>
                    <DateField
                        name="endDateTime"
                        label={translateForm('date_field_label.stay_end_date')}
                        icon={calendarIcon}
                        format="DD.MM.YYYY"
                        className={cx('global!ace-u-full-width')}
                        value={service?.endDateTime || ''}
                        onBlur={value => handleErrors('endDateTime', value)}
                        errors={errors.endDateTime ? [translate('global.error.empty_mandatory_field')] : []}
                    />
                </ContentItem>
            </ContentBlock>
            <p
                className={cx([
                    'global!ace-u-typography--variant-body-medium',
                    'global!ace-u-margin--small-bottom-16',
                ])}
            >
                {translateForm('title.number_of_people')}
            </p>
            <InputCounter
                className={cx([
                    'global!ace-u-margin--small-bottom-32',
                ])}
                name="numberOfPeople"
                value={service?.numberOfPeople || ''}
                onBlur={value => handleErrors('numberOfPeople', value)}
                errors={errors.numberOfPeople ? [translate('global.error.empty_mandatory_field')] : []}
            />
            <p
                className={cx([
                    'global!ace-u-typography--variant-body-medium',
                    'global!ace-u-margin--small-bottom-16',
                ])}
            >
                {translateForm('title.place_of_accommodation')}
            </p>
            <InputField
                placeholder={translateForm('input_placeholder.accommodation_name')}
                name="name"
                label={translateForm('input_label.accommodation_name')}
                className={cx([
                    'global!ace-u-full-width',
                    'global!ace-u-margin--large-bottom-32',
                    'global!ace-u-margin--small-bottom-24',
                ])}
                value={service?.name || ''}
                onBlur={value => handleErrors('accommodationName', value)}
                errors={errors.accommodationName ? [translate('global.error.empty_mandatory_field')] : []}
            />
            <p
                className={cx([
                    'global!ace-u-typography--variant-body-medium',
                    'global!ace-u-margin--small-bottom-16',
                ])}
            >
                {translateForm('title.comment')}
            </p>
            <TextAreaField
                placeholder={translateForm('text_area_placeholder.description')}
                name="description"
                value={service?.description || ''}
                maxLength={250}
                isResizable={false}
                className={cx([
                    'ace-c-text-area--small',
                    'global!ace-u-margin--small-bottom-32',
                    'global!ace-u-full-width',
                ])}
                label={translateForm('text_area_label.description')}
            />
        </Form>
    );
};

AccommodationSubForm.propTypes = {
    service: PropTypes.object.isRequired,
};

export default AccommodationSubForm;
